.form-row .col-md-12 .basicInfoFields  #Specialities .rbt:first-child {
	display: flex;
  }

  
 .form-row  .col-md-12 .rbt-input  {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
	overflow: hidden;
	max-height: 8rem!important;
    height: 7rem!important;

} 

 .basicInfoFields .rbt-input-multi .rbt-input-wrapper{
	align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
	overflow: hidden;
}


.basicInfoFields .rbt-input-multi .rbt-input-wrapper .rbt-token {
    background-color: #e7f4ff;
    border: 0;
    border-radius: .25rem;
    color: #007bff;
    display: inline-block;
    line-height: 1em;
    margin: 1px 3px 2px 0;
    padding: 4px 20px;
    position: relative;
}

.basicInfoFields .rbt-input-multi .rbt-input-wrapper .rbt-token .rbt-token-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: 400;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
}

.rbt-input-multi{
	overflow: hidden;
}

.featuresCEdit .rbt-input-multi.form-control, .affliatedTagCEdit .rbt-input-multi.form-control {
	min-height: 100px!important;
    overflow-y: auto;
    margin-bottom: 30px;
}