.accordion-head i{
    font-size: 26px;
    float: right;
}
/* .fa-angle-down:before {
	content: "\f107";
	font-size: 26px;
	color: black;
} */


 .accordion-head > .collapsed > i:before{
	font-size: 26px;
	color: black;
	content: "\f107";
} 



/* .accordion-head > .collapsed > i:after{
	content: "\f105";
	font-size: 26px;
	color: black;
} */