html, body {
    height: 100%;
    background-color: #FAFAFA;
}
.container {
    min-height: 100vh;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    background-color: #FAFAFA;
}
.loginImage {
    background-image: url('../images/login.png')
}
.loginForm {
    border: 0.69px solid #D0D0D0;
}

.invalid-feedback {
	display: block !important;
}



